import { Button, Image, message, Modal, Tabs } from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import { CheckCircleOutlined, PlusCircleFilled, MinusCircleFilled, ShareAltOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import noImage from "../../assets/images/no-image.png";
import { addMyTrip, setIsGuest } from '../../store/features/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatTime } from '../../utils/functions';
import { FacebookShareButton, FacebookIcon } from "react-share";
import axios from 'axios';

const TripCard = ({ tripInfo }) => {

    const { tripImages, prices, categories } = useSelector(state => state.trip);
    const { logedInUser } = useSelector(state => state.user);
    const [tripImage, setTripImage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState({ tripInfo: {}, selectedPersons: [] });
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [guestModalOpen, setGuestModalOpen] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [showFullDetails, setShowFullDetails] = useState(false);
    const [tripPrices, setTripPrices] = useState("");
    const [pointsArray, setPointsArray] = useState([]);
    const [tripCat, setTripCat] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const shareTrip = useRef();

    useEffect(() => {
        (async () => {
            try {
                const latestPoint = await axios.get(`${process.env.REACT_APP_BASE_URL}/getpoints.php?tid=${tripInfo.id}&t=${new Date().getTime()}`);
                setPointsArray(latestPoint.data);
            } catch (error) {

                console.log(error);
            }
        })()
    }, [tripInfo]);

    useEffect(() => {
        if (tripInfo) {
            setTripCat(categories.find(item => +item.id === +tripInfo.cid));
        }
    }, [categories, tripInfo]);

    useEffect(() => {
        let image = tripImages.filter((img) => img.tid === tripInfo.id);
        const priceOfTrips = prices.filter(item => +item.tid === +tripInfo.id) || [];
        setTripPrices(priceOfTrips);
        setTripImage(image);
    }, [tripInfo, tripImages, prices]);

    const book = () => {
        showModal();
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const addTripToCart = () => {

        if (+totalAmount === 0) {
            message.error("Please Select Persons");
            return;
        }

        if (!logedInUser) {
            setGuestModalOpen(true);
            return;
        }

        setIsModalOpen(false);
        let tripCart = { ...selectedItems, tripamount: totalAmount, pickupPoints: pointsArray, tripId: tripInfo.id };
        dispatch(addMyTrip(tripCart));
        message.success("Fill Form");
        navigate(`/booking/${tripInfo.id}`);

    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (prices && prices.length > 0 && tripInfo) {
            const tripPrices = prices.filter(price => price.tid === tripInfo.id);
            if (tripPrices.length > 0) {
                const initialSelectedItems = tripPrices.map(price => ({ agegroup: price.agegroup, price: price.price, quantity: 0 }));
                setSelectedItems({ tripInfo: tripInfo, selectedPersons: initialSelectedItems });
            }
        }
    }, [prices, tripInfo]);

    const handleQuantityChange = (index, value) => {
        setSelectedItems(prevSelectedItems => {
            const newSelectedItems = { ...prevSelectedItems };
            newSelectedItems.selectedPersons[index] = {
                ...newSelectedItems.selectedPersons[index],
                quantity: value >= 0 ? value : 0
            };
            calculateTotalAmount(newSelectedItems.selectedPersons);
            return newSelectedItems;
        });
    };

    const calculateTotalAmount = (items) => {
        let total = 0;
        items.forEach(item => {
            total += item.price * item.quantity;
        });
        setTotalAmount(total.toFixed(2));
    };

    const toggleDetails = () => {
        setShowFullDetails(!showFullDetails);
    };

    const toggleShareModal = () => {
        setShareModalOpen(!shareModalOpen);
    }

    const closeGuestModal = () => {
        setGuestModalOpen(false);
    }

    const handleLogin = () => {
        closeGuestModal();
        let tripCart = { ...selectedItems, tripamount: totalAmount, pickupPoints: pointsArray, tripId: tripInfo.id };
        dispatch(addMyTrip(tripCart));
        navigate("/signin");
    }

    const handleGuest = () => {
        dispatch(setIsGuest(true));
        closeGuestModal();
        let tripCart = { ...selectedItems, tripamount: totalAmount, pickupPoints: pointsArray, tripId: tripInfo.id };
        dispatch(addMyTrip(tripCart));
        message.success("Fill Form");
        navigate(`/booking/${tripInfo.id}`);
    }
    const tripInformation = [
        {
            key: "1",
            label: "Details",
            children: (
                <div
                    className="editorContent"
                    dangerouslySetInnerHTML={{ __html: tripInfo.details }}
                />
            ),
        },
        tripInfo.includes !== "" && {
            key: "2",
            label: "Includes",
            children: tripInfo.includes !== "" && (
                <ul className="list-disc">
                    {tripInfo.includes?.split(",").map((item, index) => (
                        <li key={index} className
                        >
                            <CheckCircleOutlined className="text-themeBlue mr-2" />
                            {item}
                        </li>
                    ))}
                </ul>
            ),
        },
        tripImage.length > 1 && {
            key: "3",
            label: "Gallery",
            children: (
                <div>
                    <h2 className="text-[18px] font-medium text-txtDark mb-4">Images</h2>
                    <div className="flex flex-wrap gap-3">
                        {tripImage.slice(1).map((img) => (
                            <div className="relative" key={img.id}>
                                <Image
                                    key={img.id}
                                    src={img.source}
                                    className="w-[120px] h-[120px]"
                                    loading="lazy"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ),
        },
    ];

    const copyTripShareUrl = () => {
        shareTrip.current?.select();
        shareTrip.current.style.color = "blue";
        window.navigator.clipboard.writeText(process.env.REACT_APP_SHARE_URL + tripInfo.id);
    }

    if (tripInfo) {
        return (
            <div className='relative grid grid-cols-12 gap-y-7 sm:gap-8 items-center px-4 w-full sm:px-8 py-7 bg-white shadow-xl rounded-2xl mb-4'>
                <div onClick={toggleShareModal} className='absolute top-[3px] right-[10px] sm:top-[10px] sm:right-[15px] sm:text-[25px] text-[20px] text-themeBlue cursor-pointer'>
                    <ShareAltOutlined />
                </div>
                {tripImage && tripImage[0] &&
                    <div className="col-span-12 lg:col-span-3 flex justify-center md:block">
                        <Image src={tripImage && tripImage[0] ? tripImage[0].source : noImage} className='w-[280px] h-[400px] sm:h-[300px] sm:w-[400px] max-sm:w-full' />
                    </div>
                }
                <div className={`col-span-12 ${tripImage && tripImage[0] ? "sm:col-span-9" : "sm:col-span-12"}`}>
                    <h2 className='text-[20px] sm:text-[28px] mb-2 font-semibold text-themeBlue'>{tripInfo.title}</h2>
                    <h3 className='text-[16px] sm:text-[20px] text-gray-600 mb-2'>{tripInfo.subtitle}</h3>
                    <div className="grid grid-cols-2 gap-3 sm:flex sm:flex-wrap sm:gap-8 mt-2">
                        <div>
                            <p className='text-[14px] sm:text-[17px] font-semibold text-gray-800 mb-2 max-sm:mb-1'>Start Date</p>
                            <p className='text-gray-600 max-sm:text-[15px]'>{formatDate(tripInfo.startdate)}</p>
                        </div>
                        <div>
                            <p className='text-[14px] sm:text-[17px] font-semibold text-gray-800 mb-2 max-sm:mb-1'>End Date</p>
                            <p className='text-gray-600 max-sm:text-[15px]'>{formatDate(tripInfo.enddate)}</p>
                        </div>
                        {tripInfo.starttime &&
                            <div>
                                <p className='text-[14px] sm:text-[17px] font-semibold text-gray-800 mb-2 max-sm:mb-1'>Start Time</p>
                                <p className='text-gray-600 max-sm:text-[15px]'>{tripInfo.starttime && formatTime(tripInfo.starttime)}</p>
                            </div>
                        }
                        {tripInfo.endtime &&
                            <div>
                                <p className='text-[14px] sm:text-[17px] font-semibold text-gray-800 mb-2 max-sm:mb-1'>End Time</p>
                                <p className='text-gray-600 max-sm:text-[15px]'>{tripInfo.endtime && formatTime(tripInfo.endtime)}</p>
                            </div>
                        }
                    </div>
                    <div className='flex justify-between items-center'>
                        {tripPrices.length > 0 && (
                            <div className="flex gap-3 items-center mt-4 max-sm:flex-col max-sm:items-start">
                                <p className='text-[17px] font-medium'>Prices: </p>
                                <div className='flex gap-3 max-sm:flex-wrap items-center'>
                                    {tripPrices.map((person) => (
                                        <div key={person.id} >
                                            <p className='text-[17px] font-medium max-sm:text-[14px]'>{person.agegroup} / £{person.price} </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                    </div>
                    {pointsArray.length > 0 && (
                        <div className="mt-4">
                            <p className='text-[17px] font-medium'>Pickup Points </p>
                            <div className='flex gap-3 max-sm:flex-wrap items-center'>
                                {pointsArray.map((point) => (
                                    <div key={point.pid} >
                                        <p className='text-[17px] text-gray-600 max-sm:text-[14px]'>{point.pname} &nbsp; </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className='flex gap-3 sm:justify-between items-center'>
                        {tripCat && Object.keys(tripCat).length > 0 &&
                            <p className='font-bold text-lg mt-1'>
                                {tripCat.name}
                            </p>}
                        <div className='flex gap-2'>
                            <button onClick={toggleDetails} className='shadow-md mt-4 rounded-md bg-themeBlue text-[16px] py-1 px-2 sm:text-[18px] sm:py-2 sm:px-8 font-medium hover:bg-themeRed duration-300 text-white'>Trip Details</button>
                            <button onClick={book} className='shadow-md mt-4 rounded-md bg-themeBlue text-[16px] py-1 px-2 sm:text-[18px] sm:py-2 sm:px-8 font-medium hover:bg-themeRed duration-300 text-white'>Book</button>
                        </div>
                    </div>
                </div>
                <Modal
                    title={tripInfo.title}
                    open={isModalOpen}
                    onOk={addTripToCart}
                    onCancel={handleCancel}
                    okText="Book"
                    cancelText="Cancel"
                    okButtonProps={{ className: "bg-themeBlue hover:bg-themeRed" }}
                    cancelButtonProps={{ className: "bg-themeRed text-white hover:bg-themeBlue" }}
                >
                    <h3 className='text-[18px]'>Start Date :{formatDate(tripInfo.startdate)}</h3>
                    <div className="flex flex-col space-y-4 mb-4">
                        {selectedItems.selectedPersons.map((item, index) => (
                            <div key={index} className="flex justify-between items-center mt-4">
                                <div>{item.agegroup} / £{item.price}</div>
                                <div className="flex items-center">
                                    <MinusCircleFilled
                                        onClick={() => handleQuantityChange(index, (item.quantity || 0) - 1)}
                                        className='text-themeRed text-[17px]'
                                        style={{ userSelect: 'none' }} // Add this line
                                    />
                                    <input
                                        type="text"
                                        value={item.quantity || '0'}
                                        onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                                        className="w-8 text-center"
                                    />
                                    <PlusCircleFilled
                                        onClick={() => handleQuantityChange(index, (item.quantity || 0) + 1)}
                                        className='text-themeBlue text-[17px]'
                                        style={{ userSelect: 'none' }} // Add this line
                                    />
                                </div>
                            </div>
                        ))}
                        <div className="flex justify-end items-center">
                            <div>
                                Price £{totalAmount}
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={showFullDetails}
                    onCancel={toggleDetails}
                    footer={null}
                    closeIcon={null}
                    cancelButtonProps={{
                        className: "bg-themeRed text-white border-none",
                    }}
                >
                    <Tabs
                        defaultActiveKey="1"
                        items={tripInformation}
                        className="custom-tabs"
                        style={{ height: "300px", overflowY: "auto" }}
                    />
                    <div className="flex justify-end pt-4 pr-4">
                        <Button
                            className="text-white bg-themeRed border-none"
                            onClick={toggleDetails}
                        >
                            Close
                        </Button>
                    </div>
                </Modal>

                <Modal
                    open={shareModalOpen}
                    onCancel={toggleShareModal}
                    footer={null}
                >
                    <h2 className='text-[18px] mb-6'>Share This Trip:</h2>
                    <div className="flex shadow rounded-lg overflow-hidden mb-4">
                        <input
                            type="text"
                            className="outline-none w-full py-1 px-3"
                            placeholder="Password"
                            readOnly
                            value={`${process.env.REACT_APP_SHARE_URL}${tripInfo.id}`}
                            ref={shareTrip}
                        />
                        <button onClick={copyTripShareUrl}
                            className='outline-none bg-themeBlue text-white px-3 py-0.5 shrink-0'
                        >copy</button>
                    </div>
                    <div className='flex justify-center mt-4'>
                        <FacebookShareButton
                            url={`${process.env.REACT_APP_SHARE_URL}${tripInfo.id}`}
                            quote={tripInfo.title}
                            hashtag={tripInfo.title}
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                    </div>
                </Modal>
                <Modal
                    title="Select User Mode"
                    open={guestModalOpen}
                    onCancel={closeGuestModal}
                    footer={null}
                >
                    <div className='flex justify-center items-center pb-4'>
                        <div>
                            <Button onClick={handleLogin} type="primary" className='w-full bg-themeBlue hover:bg-themeRed' classNames="mt-2">Login Now</Button>
                            <hr className='w-full my-5 h-[2px] bg-gray-200' />
                            <Button onClick={handleGuest} type="primary" className='w-full bg-themeRed text-white hover:bg-themeBlue' classNames="mt-2">Go As Guest</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    } else {
        return (
            <div className='py-[30] sm:py-[100px] text-center'>
                <h3 className='text-[35px] text-gray-700 font-semibold'>No Trip Found</h3>
            </div>
        );
    }
}

export default memo(TripCard);
